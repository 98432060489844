<template>
  <div class="flex-container non-scrollable">
    <page-title value="Préparation paie"/>
    <paie-tableau-porte/>
  </div>
</template>

<script>

import PaieTableauPorte from "@/components/Paie/PaieTableauPorte";
import PageTitle from "@/components/PageTitle.vue";
export default {
  name: "RHPortes",
  components: {PageTitle, PaieTableauPorte },
}
</script>

<style scoped>

</style>
